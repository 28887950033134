<template>
  <div>
    <!-- Services -->
    <b-row class="services w-25">
      <!-- Select Service -->
      <b-col md="8">
        <validation-observer ref="serviceForm">
          <b-form-group :label="serviceLabel">
            <Transition
              name="slide"
              class="position-relative"
            >
              <!-- Add New Service -->
              <div
                v-if="serviceAction"
                class="d-flex align-items-baseline position-absolute"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="service name"
                  rules="required"
                >
                  <b-form-input
                    v-model="form.name"
                    class="service-input"
                    placeholder="Service Name"
                    autofocus
                    @keypress.enter="onSubmit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

                <b-button
                  v-b-tooltip.hover="'Save'"
                  variant="success"
                  class="mx-1"
                  @click="onSubmit"
                >
                  <feather-icon icon="CheckIcon" />
                </b-button>

                <b-button
                  v-b-tooltip.hover="'Discard'"
                  variant="danger"
                  @click="() => { serviceAction=null; form.value = {} }"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </div>

              <!-- Select Service -->
              <v-select
                v-else
                v-model="currentService"
                class="w-100 position-absolute"
                :options="serviceOptions"
                :clearable="false"
                :reduce="s => s.value"
                placeholder="Select a Service"
                @input="onServiceSelected"
              />
            </Transition>
          </b-form-group>
        </validation-observer>
      </b-col>

      <b-col
        v-if="!serviceAction && self.is_superuser"
        md="4"
      >
        <div
          class="d-flex align-items-center"
          :style="'margin-top: 26px'"
        >
          <!-- Update Service -->
          <b-button
            v-b-tooltip.hover="'Update Service'"
            class="mx-1"
            variant="primary"
            :disabled="!currentService"
            @click="onUpdate"
          >
            <feather-icon icon="Edit2Icon" />
          </b-button>

          <!-- Add Service -->
          <b-button
            v-if="!serviceAction"
            v-b-tooltip.hover="'Add Service'"
            variant="primary"
            @click="serviceAction = 'create'"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>

          <!-- Add Keys -->
          <b-button
            v-b-tooltip.hover="'Add Keys'"
            class="ml-1"
            variant="success"
            :disabled="!currentService"
            @click="goToServicekey"
          >
            <feather-icon icon="KeyIcon" />
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script setup>
import {
  computed, getCurrentInstance, onMounted, ref, watch,
} from 'vue'
import {
  BCol, BRow, BFormGroup, BButton, BFormInput, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import router from '@/router'

const vBTooltip = VBTooltip
const root = getCurrentInstance().proxy
const currentService = ref(null)
const services = ref()
const serviceOptions = ref([])
const serviceAction = ref(null)
const form = ref({})
const serviceForm = ref()
const self = getUserData()

const emit = defineEmits(['onServiceSelected', 'setGateways', 'processing'])

watch(serviceAction, value => {
  if (!value) form.value = {}
})

const serviceLabel = computed(() => {
  if (serviceAction.value) {
    return serviceAction.value === 'create' ? 'Add Service' : 'Update Service'
  }
  return 'Service'
})

const getGatewaysByService = () => {
  const service = services.value.find(s => s.uuid === currentService.value)
  if (!service.gateways || !service.gateways.length) {
    emit('processing', true)
    const params = { service: currentService.value }
    useJwt.getGateways({ params }).then(res => {
      const gateways = res.data
      if (gateways) {
        service.gateways = gateways
        emit('setGateways', gateways)
      }
    }).catch(error => {
      this.showErrorMessage(error)
    }).finally(() => {
      emit('processing', false)
    })
  } else emit('setGateways', service.gateways)
}

const getServices = (selectedService = null) => {
  emit('processing', true)
  useJwt.getServices().then(response => {
    const { data } = response
    services.value = data
    if (data.length) {
      serviceOptions.value = data.map(service => ({
        label: service.name,
        value: service.uuid,
      }))

      const firstService = data[0]
      currentService.value = selectedService ?? firstService?.uuid

      emit('onServiceSelected', selectedService ?? currentService.value)
      getGatewaysByService()
    }
  }).catch(error => {
    this.showErrorMessage(error)
  }).finally(() => {
    emit('processing', false)
  })
}

const onSubmit = () => {
  serviceForm.value.validate().then(success => {
    if (success) {
      let api = 'storeService'
      if (serviceAction.value === 'update') api = 'updateService'

      useJwt[api](form.value).then(response => {
        if (serviceAction.value === 'create') getServices(response.data.data.uuid)
        else getServices()
        root.showSuccessMessage(response)
        serviceAction.value = null
        form.value = {}
      }).catch(error => {
        root.showErrorMessage(error)
      })
    }
  })
}

const onServiceSelected = () => {
  emit('onServiceSelected', currentService)
  getGatewaysByService()
}

const onUpdate = () => {
  const { uuid, name } = services.value.find(service => service.uuid === currentService.value)
  form.value = {
    uuid,
    name,
  }
  serviceAction.value = 'update'
}

const goToServicekey = () => router.push({
  name: 'service-keys',
  params: { serviceId: currentService.value },
})

onMounted(() => {
  getServices()
})

</script>

<style lang="scss" scoped>
.services {
  min-height: 84px;
  .service-input {
    width: 240px;
  }
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease;
}

.slide-enter {
  transform: translateX(-50%) !important;
  opacity: 0 !important;
}
.slide-leave-to {
  transform: translateX(50%) !important;
  opacity: 0 !important;
}
</style>
